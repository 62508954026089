// CSS Custom Properties (Global Variables) ---------------
:root {
    --theme-color: #0275b1;
    --theme-color-hover: #03a5ea;
}

// Font Family
$poppins: "Poppins", sans-serif;
$abrilfatface: "Abril Fatface", cursive;
$cormorantgaramond: "Cormorant Garamond";
$greatvibes: "Great Vibes", cursive;
$lobster: "Lobster", cursive;
$josefin: "Josefin Sans", sans-serif;

// text Colors ---------------
$white: #fcfcfc;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;

// bg Colors ---------------
$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #e9e7e7;
$bg-purple: #f0e0ff;
$bg-orange: #ea5f1c;

// Heading Color
$heading-color: #000000;

// Theme Color
$theme-color: var(--theme-color); // Referencing CSS custom property;
$theme-color-hover: var(--theme-color-hover);
$banner-top-color: white;
$banner-bottom-color: #18181b;
$banner-menu-open-color: #0e0e10;
$theme-gray-1: #0e0e10;
$theme-gray-2: #A9A9A9;
$theme-red: #fe5252;
$theme-badge-discount: #58585A;
$theme-brand-btn: #747474;
$theme-p: #4b4b4b;
$theme-breadcrumb: #5b5858;
$username-color: black;
$arrow-color: white;
$underline-color: #2b2b2a;
$phone-hours-wrapper-bg: $bg-grey;
$phone-hours-wrapper-hover-bg: $bg-grey;
$phone-hours-wrapper-hover-text: $theme-color-hover;
$hover-gray: #A9A9A9;
$in-stock-green: #87ac40;
$delivery-orange: #e98c38;

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";

$login-grid-template: 38% 24% 38%;