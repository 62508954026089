.searchcontentbox {
  margin-top: 20px;
  /* border-radius: 60px; */
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
}

.searchcontentbox button {
  color: #a49b9c;
  float: right;
  padding: 1px 1px;
  margin-top: 8px;
  background: none;
  font-size: 17px;
  border: none;
  cursor: pointer;
  margin-top: auto;
  height: 45px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  width: 5%;
}

.arrowbottom {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $theme-color;
  position: relative;
  left: 50%;
  top: 100%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  margin-top: 3px;
}

@media (max-width: 991px) {
  .center-icons-inside {
    display: flex;
    justify-content: right;
    align-items: center;
  }

  .searchcontentbox {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}